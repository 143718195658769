import { Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import iconAdm from '../../assets/icons/Login/icon-adm.png';
import iconSin from '../../assets/icons/Login/icon-sindico.png';
import iconCon from '../../assets/icons/Login/icon-condomino.png';
import iconImob from '../../assets/icons/Login/icon-imobiliarias.png';
import iconFunc from '../../assets/icons/Login/icon-funcionario.png';
import iconServ from '../../assets/icons/Login/icon-servicos.png';
import iconForn from '../../assets/icons/Login/icon-fornecedor.png';
import iconadv from '../../assets/icons/Login/icon-advocacia.png';
import iconGest from '../../assets/icons/Login/icon-gestor.png';

import iconAdmBranco from '../../assets/icons/Login/icon-adm-branco.png';
import iconSinBranco from '../../assets/icons/Login/icon-sindico-branco.png';
import iconConBranco from '../../assets/icons/Login/icon-condomino-branco.png';
import iconImobBranco from '../../assets/icons/Login/icon-imobiliarias-branco.png';
import iconFuncBranco from '../../assets/icons/Login/icon-funcionario-branco.png';
import iconServBranco from '../../assets/icons/Login/icon-servicos-branco.png';
import iconFornBranco from '../../assets/icons/Login/icon-fornecedor-branco.png';
import iconadvBranco from '../../assets/icons/Login/icon-advocacia-branco.png';
import iconGestBranco from '../../assets/icons/Login/icon-gestor-branco.png';
import iconValidadorAssinaturaTermo from '../../assets/icons/Login/check-double-solid.svg';
import iconValidadorAssinaturaTermoBranco from '../../assets/icons/Login/check-double-solid-branco.svg';
import { LoginEndpoints } from './enum';

export const MotionFlex = motion(Flex);

export const loginOptions = [
  {
    title: 'Portal da administradora',
    route: LoginEndpoints.Administradora,
    icon: iconAdm,
    iconWhite: iconAdmBranco,
    redirect: false
  },
  {
    title: 'Portal do síndico',
    route: LoginEndpoints.Sindico,
    icon: iconSin,
    iconWhite: iconSinBranco,
    redirect: false
  },
  {
    title: 'Portal do condômino',
    route: LoginEndpoints.Condomino,
    icon: iconCon,
    iconWhite: iconConBranco,
    redirect: false
  },
  {
    title: 'Portal das imobiliárias',
    route: LoginEndpoints.Imobiliaria,
    icon: iconImob,
    iconWhite: iconImobBranco,
    redirect: false
  },
  {
    title: 'Portal do funcionário do condomínio',
    route: LoginEndpoints.Condominio,
    icon: iconFunc,
    iconWhite: iconFuncBranco,
    redirect: false
  },
  {
    title: 'Portal do prestador de serviços',
    route: LoginEndpoints.Servicos,
    icon: iconServ,
    iconWhite: iconServBranco,
    redirect: false
  },
  {
    title: 'Portal do fornecedor de produtos',
    route: LoginEndpoints.Produtos,
    icon: iconForn,
    iconWhite: iconFornBranco,
    redirect: false
  },
  {
    title: 'Portal dos escritórios de advocacia',
    route: LoginEndpoints.Advocacia,
    icon: iconadv,
    iconWhite: iconadvBranco,
    redirect: false
  },
  {
    title: 'Portal do gestor condominial/SP',
    route: LoginEndpoints.Parceira,
    icon: iconGest,
    iconWhite: iconGestBranco,
    redirect: false
  },
  {
    title: 'Carta de adimplência',
    route: LoginEndpoints.Adimplencia,
    icon: iconAdm,
    iconWhite: iconAdmBranco,
    redirect: false
  },
  {
    title: 'Validador Assinatura de Termo de Acordo',
    route: 'https://dev.classecon.com.br/termo-acordo/validador',
    icon: iconValidadorAssinaturaTermo,
    iconWhite: iconValidadorAssinaturaTermoBranco,
    redirect: true,
  },
];
